import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/using-es6-babel",
  "date": "2016-07-11",
  "title": "USING ES6 WITH BABEL",
  "author": "admin",
  "tags": ["development", "javascript", "es6"],
  "featuredImage": "feature.jpg",
  "excerpt": "Some of the modern browsers are yet to catch up with the new language additions to ES6. This means we have to use Babel to transpile our code into ES5."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Let’s look at the steps we need to follow to get this going.`}</p>
    <h2>{`Install Babel`}</h2>
    <p>{`Installing Babel CLI is the first thing we’ll do.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install babel-cli ––save–dev
`}</code></pre>
    <p>{`We have to install addons to use new syntax additions, so let’s install `}<inlineCode parentName="p">{`babel-preset-es2015`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install babel-preset-es2015 ––save–dev
`}</code></pre>
    <h2>{`Create `}<inlineCode parentName="h2">{`.babelrc`}</inlineCode></h2>
    <p>{`In order to create a preset to use Babel, we have to create a `}<inlineCode parentName="p">{`.babelrc`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "presets" : ["es2015"]
}
`}</code></pre>
    <p>{`Same time let’s create a `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file. Run the following command and follow the prompts.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm init
`}</code></pre>
    <p>{`Now we are ready to write some ES6 code!`}</p>
    <h2>{`Write ES6 Code`}</h2>
    <p>{`To test out the configuration is correct, let’s write our code in ES6 syntax.`}</p>
    <p>{`Create a folder called `}<inlineCode parentName="p">{`src`}</inlineCode>{` and then a file inside it called `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` and add the following code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`export const bar = foo => \`\${foo} world!\`;
`}</code></pre>
    <h2>{`Transpile to ES5`}</h2>
    <p>{`To transpile our ES6 code, we will add a command to the script section of the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"scripts": {
  "start": "./node_modules/.bin/babel src -d dist",
  ...
},
`}</code></pre>
    <p>{`Now run the following command to let the transpilation kick in.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`npm start
`}</code></pre>
    <p>{`You will find a `}<inlineCode parentName="p">{`dist`}</inlineCode>{` folder in your root directory with the transpiled code, which may look as follows.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`"use strict";
 
Object.defineProperty(exports, "__esModule", {
  value: true
});
var bar = exports.bar = function bar(foo) {
  return foo + " world!";
};
`}</code></pre>
    <h2>{`Test`}</h2>
    <p>{`To guarantee our transpiled code is working, let’s write some tests.`}</p>
    <p>{`First, let’s install `}<inlineCode parentName="p">{`Mocha`}</inlineCode>{` and `}<inlineCode parentName="p">{`Chai`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install mocha chai ––save–dev
`}</code></pre>
    <p>{`Also create a directory called `}<inlineCode parentName="p">{`test`}</inlineCode>{` and add the test script to `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"scripts": {
  "start": "./node_modules/.bin/babel src -d dist",
  "test": "mocha"
},
`}</code></pre>
    <p>{`Now let’s write a test. Create a `}<inlineCode parentName="p">{`test.js`}</inlineCode>{` file in the `}<inlineCode parentName="p">{`test`}</inlineCode>{` directory and add the following test.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var assert = require('chai').assert;
var bar = require('../dist/').bar;
 
describe('Bar', function() {
  it('Should return Hello world!', function() {
    assert.equal(
      bar('Hello'),
      'Hello world!'
    );
  });
});
`}</code></pre>
    <p>{`The test should pass and our ES6 transpilation is working!`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`ES6 and beyond is the future. Babel enables us to write futuristic code now.`}</p>
    <p>{`The source code for this article can be found here on `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/es6-babel-boilerplate"
      }}>{`GitHub`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      